/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Loader from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { ActionCreators } from "src/actions/profile";
import { getProviderRoles, logoutAndRedirect } from "src/utils/common";

const PatientDashboardRedirect = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, patient_id, excercise_id, episodeId, branch_id, therapist_id } =
    useParams();
  const profile = useSelector((state) => state.user.profile);
  const tabUid = useSelector((state) => state.user.tabUid);
  const sessionDuration = useSelector((state) => state.user.sessionDuration);
  const currentParentClinicID = useSelector(
    (state) => state.user.currentParentClinicID
  );
  const redirectToLogin = () => {
    history.push({
      pathname: "/login",
      search: "?redirect=reviewRecording",
      state: {
        sess_id: id,
        patient_id: patient_id,
        excercise_id: excercise_id,
        episodeId: episodeId,
        branch_id: branch_id,
        therapist_id: therapist_id,
      },
    });
  };
  useEffect(() => {
    const checkUserProfile = async () => {
      try {
        if (!profile.id) {
          redirectToLogin();
        } else {
          if (profile.id !== therapist_id) {
            logoutAndRedirect(profile, dispatch, tabUid, sessionDuration)
              .then(() => {
                redirectToLogin();
              })
              .catch((error) => {
                console.error("Logout failed:", error);
              });
          } else {
            getProviderRoles(
              currentParentClinicID,
              branch_id,
              profile,
              dispatch
            )
              .then(() => {
                dispatch(ActionCreators.setClinicBranch(branch_id));
                localStorage.setItem("UserClinic", branch_id);
                history.push(
                  `/patient-dashboard/${id}/${patient_id}/${excercise_id}/${episodeId}`
                );
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          }
        }
      } catch (error) {
        console.error("Error fetching current authenticated user:", error);
      }
    };

    checkUserProfile();
  }, []);
  return (
    <div data-testid="loader">
      <Loader
        type="ThreeDots"
        color="var(--primaryColor)"
        height={40}
        width={40}
        className="graph-laoder"
      />
    </div>
  );
};

export default PatientDashboardRedirect;
