export const MIN_VALUE = 2;
export const MAX_VALUE = 20;
export const MAX_VALUE_EXTRA = 50;
export const MIN_VALUE_EXTRA = 8;
export const MAX_VALUE_END = 2000;
export const SIXVALUE = 6;
// export const IDEAL_TIMEOUT_INIT = 60000;
// export const IDEAL_TIMEOUT = 900000;
// export const CIT_INTERVAL = 5000;

export const SALUTIONOPTIONS = [
  {
    id: "Dr.",
    name: "Dr.",
  },
  {
    id: "Mr.",
    name: "Mr.",
  },
  {
    id: "Mrs.",
    name: "Mrs",
  },
];
export const ROLES = [
  {
    id: "DTP",
    name: "Doctor of physiotherapy",
  },
  {
    id: "PT",
    name: "Physiotherapist",
  },
  {
    id: "PT",
    name: "Physiotherapist Associate",
  },
  {
    id: "FDS",
    name: "Front desk staff",
  },
];
export const GENDERS = [
  {
    id: "Male",
    name: "Male",
  },
  {
    id: "Female",
    name: "Female",
  },
  {
    id: "Others",
    name: "Others",
  },
];
