/* eslint-disable no-useless-escape */
export const ALPHABET_REGEX = RegExp(/^[a-zA-Z]*$/i);
export const ALPHABET_REGEX_SPACE = RegExp(/^[a-zA-Z\s]*$/i);
export const ALPHABET_REGEX_SPACE_SPEACIAL_CHAR = RegExp(/^[a-zA-Z\s'-]*$/i);

// export const ALPHABET_REGEX = RegExp(/^[a-zA-Z]*$/i);
export const ALPHABET_REGEX_ALL_NAMES = RegExp(/^[a-zA-Z'-.]*$/i);
// export const ALPHABET_NUMERIC_REGEX = RegExp(/^[^\W_]+(?: [^\W_]+)*$/);
export const ALPHABET_NUMERIC_REGEX = RegExp(/^[A-Za-z0-9- ]+$/);
//eslint-disable-next-line
// export const EMAIL_REGEX_2 = RegExp(
//   "^(?=.{1,64}@)[A-Za-z0-9\\+-]+(\\.[A-Za-z0-9\\+-]+)*@[^-][A-Za-z0-9\\+-]+(\\.[A-Za-z0-9\\+-]+)*(\\.[A-Za-z]{2,})$"
// );

export const EMAIL_REGEX = RegExp(
  "(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?)"
);

export const EMAIL_REGEX_2 = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

// export const EMAIL_REGEX = RegExp(
//   "(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?)"
// );
// export const EMAIL_REGEX_2 = RegExp(
//   /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
// );
export const PASSWORD_REGEX = RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{4,100}$/i
);
//eslint-disable-next-line
// export const PASSWORD_VALUE_REGEX = RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{4,})");
export const PASSWORD_VALUE_REGEX = RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\s/!\"#$%&'()*+,-./:;<=>?@[\\\\\\]^_`{|}~/])(?=.{4,})"
);
export const ALLTYPESEGEX = RegExp(/^[^\s].+[^\s]$/i);
export const NUMBERREGEX = RegExp(/^[0-9]*$/);
export const REG = RegExp(/^\S*$/i);
export const PHONE_US_REGEX = RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
export const ALPHABET_NUMERIC_REGEX_LIBRARY = RegExp(/^[A-Za-z0-9- ()]+$/);
export const NUMBERREGEX_WITH_HYPHEN = RegExp(/^[0-9]+(?:-[0-9]+)*$/);
